import * as React from 'react'
import StillHaveQuestions from '../components/StillHaveQuestions'
import { StarIcon } from '@heroicons/react/24/solid'
import FormWrapper from '../components/form/FormWrapper'
import InputField from '../components/form/InputField'
import isEmail from '../components/form/validators/isEmail'
import useFormState from '../components/form/hooks/useFormState'

const GoodRating = () => {
  const IconGooglePlus = ({ className }) => (
    <svg
      role="img"
      aria-label={'Google Plus'}
      className={'aspect-square fill-current' + className}
      version="1.1"
      viewBox="0 0 210 210"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <path
        className={'fill-current'}
        d="M0,105C0,47.103,47.103,0,105,0c23.383,0,45.515,7.523,64.004,21.756l-24.4,31.696C133.172,44.652,119.477,40,105,40
	c-35.841,0-65,29.159-65,65s29.159,65,65,65c28.867,0,53.398-18.913,61.852-45H105V85h105v20c0,57.897-47.103,105-105,105
	S0,162.897,0,105z"
      />
    </svg>
  )

  const IconFacebook = ({ className }) => (
    <svg
      role="img"
      aria-label={'Facebook'}
      className={'aspect-square fill-current' + className}
      version="1.1"
      viewBox="0 0 512 512"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <path
        fill="#3d5a96"
        className={'fill-current'}
        d="M283.122,122.174c0,5.24,0,22.319,0,46.583h83.424l-9.045,74.367h-74.379
		c0,114.688,0,268.375,0,268.375h-98.726c0,0,0-151.653,0-268.375h-51.443v-74.367h51.443c0-29.492,0-50.463,0-56.302
		c0-27.82-2.096-41.02,9.725-62.578C205.948,28.32,239.308-0.174,297.007,0.512c57.713,0.711,82.04,6.263,82.04,6.263
		l-12.501,79.257c0,0-36.853-9.731-54.942-6.263C293.539,83.238,283.122,94.366,283.122,122.174z"
      />
    </svg>
  )

  const IconYelp = ({ className }) => (
    <svg
      role="img"
      aria-label={'Yelp Reviews'}
      className={' aspect-square fill-current ' + className}
      version="1.1"
      viewBox="0 0 511.852 511.852"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="https://www.w3.org/1999/xlink"
    >
      <g>
        <path
          d="M204.444,329.318c11.716-3.046,19.746-13.628,20.48-27c0.742-13.619-6.69-25.737-18.27-30.063l-23.142-9.429
				c-84.036-34.662-84.386-34.662-89.899-34.731c-9.079-0.469-17.493,4.284-22.869,12.723c-0.008,0.017-0.017,0.034-0.026,0.051
				c-10.325,16.469-12.39,60.911-9.865,85.402c1.178,10.709,3.098,18.458,5.828,23.612c4.582,8.764,12.783,14.174,21.768,14.473
				c0.589,0.034,1.118,0.068,1.673,0.068c4.864,0,12.117-2.295,81.655-24.653C195.287,332.27,202.199,330.06,204.444,329.318z"
        />
        <path
          d="M244.952,346.151c-12.638-4.881-26.436-1.621-34.202,7.996c-0.009,0-14.524,17.271-16,18.97
				c-0.068,0.077-0.137,0.154-0.205,0.239c-58.803,69.009-58.957,69.461-60.8,74.778c-1.34,3.601-1.809,7.441-1.391,10.889
				c0.495,5.188,2.654,10.129,6.46,14.737c13.645,16.299,70.639,38.093,96.998,38.093c2.065,0,3.942-0.137,5.598-0.418
				c9.822-1.818,17.271-7.774,20.429-16.341c0.009-0.051,0.026-0.094,0.043-0.137c1.809-5.163,1.911-5.478,2.125-86.69
				c0,0,0.179-32.41,0.222-34.159C264.972,361.93,257.402,350.947,244.952,346.151z"
        />
        <path
          d="M442.378,359.735c-4.403-3.294-4.574-3.413-81.792-28.757c0,0-29.517-9.754-32.333-10.761
				c-0.026-0.009-0.043-0.017-0.068-0.026c-11.358-4.412-24.124-0.546-32.495,9.779c-8.61,10.505-9.711,24.704-2.876,35.123
				l13.03,21.222c47.488,77.133,48.043,77.577,52.378,80.956c4.42,3.456,9.668,5.214,15.181,5.214c3.533,0,7.168-0.717,10.778-2.185
				c21.367-8.525,64.222-63.019,67.473-85.811C453.07,374.6,449.768,365.631,442.378,359.735z"
        />
        <path
          d="M316.123,294.06c0.572,0.128,1.161,0.179,1.749,0.179c1.015,0,2.039-0.179,3.029-0.555
				c3.738-1.417,11.546-3.32,23.962-6.263c0.009,0,0.017,0,0.034-0.009c87.834-21.274,88.431-21.658,93.235-24.772
				c7.467-5.035,11.443-13.534,10.957-23.322c0.008-0.503-0.017-1.135-0.051-1.502c-2.483-23.629-40.542-79.778-60.851-89.711
				c-9.028-4.309-18.611-3.84-26.163,1.237c-4.642,3.012-4.642,3.012-52.779,68.847c0,0-19.379,26.359-20.301,27.588
				c-7.723,9.421-8.021,22.716-0.759,33.877C294.721,289.648,305.516,295.118,316.123,294.06z"
        />
        <path
          d="M254.223,19.393c-2.526-9.207-9.702-15.932-19.712-18.458c-24.269-5.973-109.457,17.929-126.686,35.669
				c-6.835,7.142-9.549,16.495-7.27,24.994c0.137,0.512,0.324,0.998,0.546,1.476c2.398,4.984,80.461,127.863,104.269,165.291
				c9.984,16.213,20.941,24.354,32.572,24.354c2.765,0,5.555-0.461,8.38-1.374c14.942-4.506,21.948-18.603,20.813-41.899
				C265.862,180.101,255.836,28.404,254.223,19.393z"
        />
      </g>
    </svg>
  )

  return (
    <div className="w-11/12 flex flex-col items-center justify-center gap-4">
      <p className="text-center max-w-max px-5 mx-auto ">
        Select one of the options below to share your experience.
      </p>
      <div className="lg:text-gray-400 flex flex-row gap-4">
        <a
          href="https://search.google.com/local/writereview?placeid=ChIJM4VAK5my2YgREKPNEWAtcI0"
          className="w-1/3 lg:w-28 lg:h-28 lg:border rounded-lg flex flex-col justify-start lg:justify-between items-center bg-gradient-to-t from-white to-white hover:from-gray-100 hover:text-red-600 duration-300 p-2"
        >
          <IconGooglePlus className=" w-14 h-14 " />
          <p>GOOGLE</p>
        </a>
        <br />
        <a
          href="https://facebook.com/grayandsonsjewelers/reviews"
          className="w-1/3 lg:w-28 lg:h-28 lg:border rounded-lg flex flex-col justify-start lg:justify-between items-center bg-gradient-to-t from-white to-white hover:from-gray-100 hover:text-blue-600 duration-300 p-2"
        >
          <IconFacebook className=" w-14 h-14 " />
          <p className="">FACEBOOK</p>
        </a>
        <br />
        <a
          href="https://www.yelp.com/biz/wtz69-SbjeNnzPt6V6XK2w"
          className="w-1/3 lg:w-28 lg:h-28 lg:border rounded-lg flex flex-col justify-start lg:justify-between items-center bg-gradient-to-t from-white to-white hover:from-gray-100 hover:text-red-700 duration-300 p-2"
        >
          <IconYelp className=" w-14 h-14 " />
          <p className="">YELP</p>
        </a>
      </div>
    </div>
  )
}

const PoorRating = () => {
  const [form, , updateForm] = useFormState({ name: '', email: '' })

  return (
    // contact form
    <div className="w-11/12 max-w-[500px] mx-auto">
      <FormWrapper form={form} name={'Rate Your Experience'} className="md:pr-2">
        <InputField
          name={'name'}
          onChange={updateForm}
          form={form}
          label="Full Name"
          showErrorMessage={false}
          required={true}
          // className="--home-subscribe-animations"
        />
        <InputField
          type={'email'}
          name={'email'}
          onChange={updateForm}
          form={form}
          label="E-Mail"
          validator={isEmail}
          required={true}
          showErrorMessage={false}
          // className="--home-subscribe-animations delay1"
        />
        <InputField
          type={'textarea'}
          rows="5"
          name={'description'}
          label={'Description'}
          form={form}
          onChange={updateForm}
          required={true}
        />
        <button className="red-raised-btn --home-subscribe-animations delay2 my-2 px-5 py-1">
          Subscribe
        </button>
      </FormWrapper>
    </div>
  )
}

const RateYourExperience = () => {
  const [goodRating, setGoodRating] = React.useState(null)
  return (
    <div className="w-full flex flex-col items-center justify-center lg:absolute inset-0 h-max lg:top-[50%] lg:translate-y-[-50%] gap-4 text-gray-500">
      <h1 className="uppercase my-5 block w-full text-4xl font-black text-center">
        How did we do?
      </h1>
      <p className="text-center w-10/12 mx-auto">
        Your feedback is important to us. Please take a moment to rate your experience with{' '}
        <nobr>Gray & Sons.</nobr>
        <br />
        We would love to hear your feedback.
        <br />
      </p>

      {/* TODO: This is where custom message would go */}

      {goodRating === false ? null : (
        <div className={'min-h-full flex flex-row items-center justify-center gap-4'}>
          {[...Array(5)].map((_, index) => (
            <StarIcon key={index} className="w-10 h-10 text-yellow-500" />
          ))}
        </div>
      )}
      {goodRating === null && (
        <div className="w-11/12 min-h-full flex flex-row items-center justify-center gap-4">
          <button
            onClick={() => setGoodRating(false)}
            className="border-2 border-gray-500 text-gray-500 hover:-translate-y-1 w-11/12 max-w-[350px] h-20 font-black  lg:text-xl lg:min-w-48 flex items-center justify-center gap-3 duration-300"
          >
            POOR SERVICE
          </button>
          <button
            onClick={() => setGoodRating(true)}
            className="border-2 border-gray-500 bg-gray-500 w-11/12 max-w-[350px] h-20 font-black text-white lg:text-xl lg:min-w-48 flex items-center justify-center gap-3 duration-300 hover:-translate-y-1"
          >
            GOOD SERVICE
          </button>
        </div>
      )}
      {goodRating === true && <GoodRating />}
      {goodRating === false && <PoorRating />}
      <StillHaveQuestions />
    </div>
  )
}

export default RateYourExperience
