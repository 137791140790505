import * as React from 'react'
import PropTypes from 'prop-types'
import GoogleAds from '../../utils/GoogleAds'
import { TrackEvent, phoneToE164 } from '../helpers/TrackingHelper'

const PhoneLink = ({ phone = '(305) 865 0999', protocol = 'tel', children, ...props }) => {
  const phoneE164 = phoneToE164(phone)
  const onClickHandler = React.useCallback(() => {
    TrackEvent(
      'phone_clicked',
      {
        phone,
      },
      {
        klaviyoEventName: 'Phone Clicked',
        googleAdsConversion: GoogleAds.Conversions.phone_clicked,
      }
    )
  }, [phone])
  return (
    <a href={`${protocol}:${phoneE164}`} onClick={onClickHandler} {...props}>
      {children || phone}
    </a>
  )
}

PhoneLink.propTypes = {
  phone: PropTypes.string,
  protocol: PropTypes.oneOf(['tel', 'sms']),
  children: PropTypes.node,
}

export default PhoneLink
