
import * as React from 'react'
import PropTypes from 'prop-types'
import GoogleAds from '../../utils/GoogleAds'
import { TrackEvent } from '../helpers/TrackingHelper'

const LocationLink = ({ children, ...props }) => {
  const location = 'https://maps.app.goo.gl/aNtfVtZo1bYeaHbR9' 
  // or https://www.google.com/maps/place/Gray+%26+Sons+Jewelers/@25.8869528,-80.1256943,17z/data=!4m5!3m4!1s0x88d9b2992b408533:0x8d702d6011cda310!8m2!3d25.8869497!4d-80.1235631
  //    https://www.google.com/maps/place/Gray+and+Sons+Jewelers+Luxury+Estate+Watches+%26+Jewelry/@25.8869528,-80.1256943,17z/data=!4m6!3m5!1s0x88d9b2992b408533:0x8d702d6011cda310!8m2!3d25.8869528!4d-80.1235056!16s%2Fg%2F1tdfzjgp?entry=ttu&g_ep=EgoyMDI0MTAyOS4wIKXMDSoASAFQAw%3D%3D
  const onClickHandler = React.useCallback(() => {
    TrackEvent(
      'location_clicked',
      {
        klaviyoEventName: 'Location Clicked',
        googleAdsConversion: GoogleAds.Conversions.location_clicked,
      }
    )
  }, [])
  return (
    <a href={location} target="_blank" rel="noreferrer" onClick={onClickHandler} {...props}>
      {children}
    </a>
  )
}

LocationLink.propTypes = {
  children: PropTypes.node,
}

export default LocationLink
