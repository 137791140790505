import * as React from 'react'
import PropTypes from 'prop-types'
import GoogleAds from '../../utils/GoogleAds'
import { TrackEvent } from '../helpers/TrackingHelper'

const EmailLink = ({ email = 'info@grayandsons.com', children, ...props }) => {
  const onClickHandler = React.useCallback(() => {
    TrackEvent(
      'email_clicked',
      {
        email,
      },
      {
        klaviyoEventName: 'Email Clicked',
        googleAdsConversion: GoogleAds.Conversions.email_clicked,
      }
    )
  }, [email])
  return (
    <a href={`mailto:${email}`} onClick={onClickHandler} {...props}>{children || email}</a>
  )
}

EmailLink.propTypes = {
  email: PropTypes.string,
  children: PropTypes.node,
}

export default EmailLink
